import React from 'react'
import { Link } from 'gatsby'
import {FaBars} from 'react-icons/fa'

const Navbar = ({ handleMenuClick }) => (
  <nav className="relative w-full bg-cloud">
    <div className="flex flex-wrap justify-between items-center py-2 px-4 xl:px-8">
      <div className="lg:hidden xl:hidden sm:block xs:block med:block">
        <FaBars onClick={ handleMenuClick } className="text-white text-2xl lg:hidden xl:hidden sm:block xs:block"/>
      </div>
      <div className="w-1/2 xl:w-auto">
        <Link to="/">
          <figure className="image">
            <img
              className="lg:h-50p xl:h-50p sm:h-auto xs:h-auto"
              src="https://d1jqsvv1ta1uec.cloudfront.net/saas/images/printercloud-product-logo-white.png"
              alt="PrinterLogic"
              loading="eager"
            />
          </figure>
        </Link>
      </div>
    </div>
  </nav>
)

export default Navbar
