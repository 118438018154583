import React, { Component } from "react"
import Helmet from 'react-helmet'

import Navbar from './NavBar.js'
import "../styles/scss/all.scss"
import "../styles/css/all.css"

class Layout extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    const { children } = this.props
    return (
      <div>
        <Helmet>
          <html lang="en" />
          <title>PrinterLogic | Video Resource Library</title>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="https://d1jqsvv1ta1uec.cloudfront.net/images/project/favicons/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="https://d1jqsvv1ta1uec.cloudfront.net/images/project/favicons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="https://d1jqsvv1ta1uec.cloudfront.net/images/project/favicons/favicon-16x16.png"
          />
          <link
            rel="manifest"
            href="https://d1jqsvv1ta1uec.cloudfront.net/images/project/favicons/manifest.json"
          />
          <link
            rel="shortcut icon"
            href="https://d1jqsvv1ta1uec.cloudfront.net/images/project/favicons/favicon.ico"
          />
        </Helmet>
        <Navbar handleMenuClick={this.props.handleMenuClick}/>
        <div className="nav-spacing">{children}</div>
      </div>
    )
  }
}

export default Layout
